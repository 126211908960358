.chatBoxOuter {
  height: 100%;
  background: var(--white);
  width: 330px;
  border-left: 1px solid var(--grey-3);
}
.meetingPageOuter {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.meetingPageHeader {
  width: 100%;
  padding: var(--spacing-20px) var(--spacing-40px);
  background: var(--grey-5);
  border-bottom: 1px solid var(--grey-3);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.33);
}

.meetingPageContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: calc(100% - 330px);
}

.recording {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: max-content;
  background: var(--grey-4);
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: var(--spacing-8px);
}

.memberImg {
  object-fit: cover;
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.membersList {
  height: 135px;
  display: flex;
  gap: var(--spacing-20px);
  flex-direction: column;
  width: 100%;
  padding: var(--spacing-24px) var(--spacing-16px);
}

.memberDetail {
  gap: var(--spacing-8px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.memberDetailsRight {
  display: flex;
  flex-direction: column;
}

.memberDetailsRight .memberName {
  font-size: 14px;
  color: var(--black-2);
  font-family: "Inter Medium";
}

.memberDetailsRight .memberPosition {
  font-size: 14px;
  color: var(--grey-1);
}

.member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.memberRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
}

.meetingVideoArea {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
  height: calc(100vh - 156px);
  background: var(--grey-4);
  position: relative;
}

.meetingVideoArea .userVideo {
  position: absolute;
  right: calc(0% + 14px);
  bottom: calc(0% + 14px);
  z-index: 9;
  max-width: 250px;
  max-height: 180px;
}

.meetingFooter {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-20px);
  border-top: 1px solid var(--grey-3);
}

.meetingButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.meetingButtons button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid var(--grey-3);
  background: var(--grey-4);
}

.meetingFooter .dangerBtn {
  position: absolute;
  right: 20px;
}

.chatBoxOuter .header {
  width: 100%;
  background: var(--grey-4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-8px);
}

.chatBoxOuter .header .heading {
  font-size: 12px;
  color: var(--black-3);
}
.chats {
  position: relative;
  padding: var(--spacing-20px) var(--spacing-16px);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-28px);
  height: calc(100% - 100px);
}

.chat {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-12px);
  justify-content: flex-start;
  flex-direction: row-reverse;
}


.chat.user{
  flex-direction: row;
}

.chatRight {
  width: 100%;
  display: flex;
  gap: var(--spacing-4px);
  flex-direction: column;
}

.chat img {
  width: 24px;
  object-fit: cover;
  height: 24px;
  border-radius: 50%;
}

.msg {
  font-size: 14px;
  width: 100%;
  padding: var(--spacing-8px) var(--spacing-12px);
  border-radius: 8px;
  background: var(--grey-4);
}

.chatRightHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatRightHeader p {
  font-size: 12px;
  color: var(--black-5);
}

.chatRightHeader p span {
  color: var(--primary-4);
}

.chatSection {
  height: calc(100vh - 200px);
  overflow: auto;
  width: 100%;
}

.sendMessageBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid var(--grey-3);
  padding: var(--spacing-16px) var(--spacing-8px);
}

.sendMessageBox input {
  border: none;
  outline: none;
  width: 90%;
  font-size: 14px;
  color: var(--black-5);
}



.userVideo.fullVideo{
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.headerContainer{
  display: flex;
  justify-content: space-between;
}