.introductionPageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background: var(--grey-4);
  gap: var(--spacing-60px);
}

.userVideo {
  position: relative;
  overflow: hidden;
  width: 800px;
  height: 450px;
  border-radius: 10px;
  border: 4px solid var(--primary-5);
  background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:28px ;
    color: #fff;
}

.userVideoBox {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.userVideoBox.small{
  width: 200px ;
}

.introductionPageContentBox {
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.introductionPageContentBox .heading{
  font-size: 28px;
  font-family: 'Inter Regular';
}

.introductionPageContentBox .formGroup{
  margin-top: var(--spacing-40px);
}

.introductionPageContainer .checkboxContainer{
  color: var(--primary-4);
}

.introductionPageContentBox .primaryBtn{
  margin-top: 80px;
}

.userVideoFooter{
  position: absolute;
  bottom: 20px;
  gap: var(--spacing-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 99;
}

.userVideoHeader{
  width: 100%;
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.userVideoHeader .videoUsername{
  left: 0;
  position: relative;
}


.videoUsername{
  font-size: 13px;
  position: absolute;
  left: 10px;
  color: var(--white);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  padding: var(--spacing-4px) var(--spacing-8px);
  border: 1px solid var(--black-1);
}

.videoBtn{
  padding: 10px;
  border-radius: 50%;
  background: var(--white);
  cursor: pointer;
  max-width: 44px;
  width: 44px;
  height: 44px;
  object-fit: cover;
}

/*////////////////////////////////////////*/

.frontpageImg{

}
footer{
  padding: 50px 0;
  background: var(--white);
}
.footerLinks{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-24px);
}

.link{
  transition: 0.3s;
  font-size: 14px;
}

.link:hover{
  color: var(--primary-5);
}
.footerContainer{
  padding-bottom: var(--spacing-28px);
  border-bottom: 1px solid var(--grey-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerContainer p{
  font-size: 14px;
  color: var(--black-1);
  font-family: 'Inter Medium';
}

.footerText{
  font-size: 14px;
  text-align: center;
  color: var(--black-5);
  margin-top: var(--spacing-28px);
}


.getperouter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 266px);
}