.appContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    /* height: calc(100vh - 68px); */
    overflow: auto;
    height: 100vh;
    /* margin-top: 68px; */
}
.routesContainer {
    width: 100%;
    height: 100%;
}